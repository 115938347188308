import React                 from 'react';

import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';



const AGBPage = (props) => {
  return (
    <>
      <Spacer/>
      <Wrapper>
        <Heading>AGB</Heading>
        <Spacer/>
        <article>
          <h4>§ 1 Geltungsbereich</h4>
          <p>
            (1) Diese allgemeinen Verkaufsbedingungen gelten in Ergänzung der Gebräuche im holzwirtschaftlichen
            Verkehr (Tegernseer Gebräuche) ausschließlich und nur gegenüber natürlichen oder juristischen Personen
            oder rechtsfähigen Personengesellschaften, die in Ausübung einer gewerblichen oder selbständigen
            Tätigkeit handeln (Unternehmern), sowie gegenüber juristischen Personen des öffentlichen Rechts und
            öffentlich rechtlichen Sondervermögen im Sinne von § 310 Abs. 1 BGB (nachfolgend: Besteller).
          </p>
          <p>
            (2) Entgegenstehende oder von unseren allgemeinen Verkaufsbedingungen abweichende Bedingungen des
            Bestellers erkennen wir nicht an, es sei denn, wir hätten ausdrücklich schriftlich ihrer Geltung
            zugestimmt. Unsere allgemeinen Verkaufsbedingungen gelten auch dann, wenn wir in Kenntnis
            entgegenstehender oder von unseren Verkaufsbedingungen abweichender Bedingungen des Bestellers die
            Lieferung vorbehaltlos ausführen.
          </p>
          <p>
            (3) Wir haben das Recht, vom Vertrag zurückzutreten, wenn der Besteller den Verkaufsbedingungen
            widerspricht. In diesem Fall sind Ansprüche seitens des Bestellers ausgeschlossen.
          </p>
          <h4>§ 2 Schriftform, Zustandekommen des Vertrages </h4>
          <p>
            (1) Die Bestellung stellt ein bindendes Angebot dar, das wir innerhalb von 2 Wochen annehmen können.
            Mündlich oder fernmündlich erteilte Bestellungen sind für uns nur verbindlich, wenn wir sie durch
            nachträgliche Übersendung einer schriftlichen Bestellung bestätigt haben (Auftragsbestätigung).
            Vorher abgegebene Angebote durch uns sind freibleibend, d.h. nur als Aufforderung zur Abgabe eines
            Angebotes zu verstehen.
          </p>
          <p>
            (2) Bestehen Abweichungen zwischen unserem Angebot und unserer Auftragsbestätigung, ist letztere
            maßgebend.
          </p>
          <p>
            (3) Alle nachträglichen Nebenabreden, Änderungen und etwaige Zusicherungen bedürfen der schriftlichen
            Bestätigung durch uns.
          </p>
          <p>
            (4) Mündliche Zusagen durch unsere Vertreter oder sonstige Hilfspersonen bedürfen der schriftlichen
            Bestätigung durch uns.
          </p>
          <p>
            (5) An Abbildungen, Zeichnungen, Kalkulationen und sonstigen Unterlagen, die zum Angebot gehören,
            behalten wir uns Eigentums- und Urheberrechte vor. Vor ihrer Weitergabe an Dritte bedarf der Besteller
            unserer ausdrücklichen schriftlichen Zustimmung. Dies gilt auch für solche schriftlichen Unterlagen, die
            als „vertraulich“ bezeichnet sind.
          </p>
          <h4>§ 3 Preise und Zahlungsbedingungen</h4>
          <p>
            (1) Die Preisbildung erfolgt in Euro. Der angebotene Kaufpreis ist als Nettopreis ausgezeichnet. Nicht
            in der Preisangabe enthalten sind folglich die Kosten für die Verpackung sowie die gesetzliche
            Umsatzsteuer. Diese werden von uns am Tag der Rechnungsstellung in der Rechnung gesondert ausgewiesen.
          </p>
          <p>
            (2) Es ist das ausgewiesene Entgelt zu zahlen, soweit nicht etwas anderes ausdrücklich vereinbart wurde.
          </p>
          <p>

            (3) Hat sich der Preis zum Zeitpunkt der Leistungserbringung durch eine Änderung des Marktpreises oder
            durch die Erhöhung der von in die Leistungserbringung einbezogenen Dritten verlangten Entgelte erhöht,
            so gilt der höhere Preis. Liegt dieser 20% oder mehr über dem vereinbarten Preis, so hat der Besteller
            das Recht vom Vertrag zurückzutreten. Dieses Recht muss unverzüglich nach Mitteilung des erhöhten
            Preises geltend gemacht werden.
          </p>
          <p>
            (4) Rechnungen sind sofort nach Zugang ohne Skontoabzug zu bezahlen, sofern sich aus der
            Auftragsbestätigung nichts anderes ergibt. Der Besteller kommt spätestens 30 Tage nach Fälligkeit in
            Verzug. Es gelten die gesetzlichen Regeln bezüglich der Folgen des Zahlungsverzuges.
          </p>
          <p>
            (5) Die Aufrechnung gegen unsere Forderung ist unzulässig, soweit die Forderung des Bestellers nicht
            unbestritten oder rechtskräftig festgestellt ist. Ein Zurückbehaltungsrecht des Bestellers ist
            ausgeschlossen, es sei denn die Gegenforderung des Bestellers stammt aus demselben Vertragsverhältnis
            und ist unbestritten oder rechtskräftig festgestellt.
          </p>
          <p>
            (6) Wechsel und Schecks werden nur erfüllungshalber angenommen und führen nicht zur Stundung der
            fälligen Forderung.
          </p>
          <p>
            (7) Ratenzahlungen werden nur aufgrund besonderer schriftlicher Vereinbarung akzeptiert.
          </p>
          <p>
            (8) Ist eine Teilzahlung vereinbart, so wird die gesamte Forderung fällig, wenn der Besteller mit der
            vereinbarten Zahlung mehr als 7 Tage in Verzug ist.
          </p>
          <p>
            (9) Unsere Vertreter sind zur Entgegennahme von Zahlungen nicht ermächtigt, es sei denn dem Besteller
            wird eine Inkassovollmacht vorgelegt.
          </p>
          <h4>§ 4 Lieferzeit und Lieferumfang</h4>
          <p>
            (1) Soweit Lieferfristen nicht ausdrücklich als verbindlich vereinbart werden, sind angegebene
            Lieferfristen nur als voraussichtliches Lieferdatum zu verstehen. Fixe Liefertermine bedürfen zu ihrer
            Verbindlichkeit unserer ausdrücklichen schriftlichen Bestätigung.
          </p>
          <p>
            (2) Der Beginn der von uns angegebenen Lieferzeiten setzt die Abklärung aller technischen Fragen voraus.
            Grundsätzlich beginnt die vereinbarte Lieferfrist mit der Absendung der Auftragsbestätigung.
            Nachträgliche Änderungen, die Art und oder Umfang des Leistungsgegenstandes betreffen, setzen eine neue
            Lieferfrist in Lauf.
          </p>
          <p>
            (3) Soweit eine Mitwirkungspflicht des Bestellers notwendig ist, beginnt die Lieferfrist nicht zu
            laufen, bevor der Besteller diese Pflicht erfüllt hat.
          </p>
          <p>
            (4) Kommt der Besteller in Annahmeverzug, hat er die uns hieraus entstehenden Kosten in voller Höhe zu
            tragen. Im Übrigen gelten die gesetzlichen Bestimmungen.
          </p>
          <p>
            (5) Aus begründetem Anlass sind wir jederzeit zu Teillieferungen und Teilleistungen berechtigt.
          </p>
          <p>
            (6) In Fällen höherer Gewalt, insbesondere Streik, Aussperrung, Krieg, Rohstoff- und Energiemangel,
            nicht von uns zu vertretenden Betriebs- und Verkehrsstörungen sowie behindernden hoheitlichen
            Verfügungen suspendieren die Vertragsverpflichtungen der Parteien um die Dauer der Störung und im Umfang
            ihrer Wirkung, auch soweit sie die Durchführung des betroffenen Geschäftes auf absehbare Zeit
            unwirtschaftlich machen. Dies gilt auch, wenn einer der oben genannten Fälle höherer Gewalt bei unseren
            Lieferanten eintritt und eine andere Liefermöglichkeit nicht oder aber nur unter unzumutbaren
            Bedingungen besteht.
          </p>
          <p>
            Überschreiten die sich daraus ergebenden Verzögerungen den Zeitraum von sechs Wochen, sind beide
            Parteien berechtigt hinsichtlich des betroffenen Lieferumfanges vom Vertrag zurückzutreten.
          </p>
          <p>
            Schadensersatzansprüche des Bestellers sind in den Fällen höherer Gewalt ausgeschlossen.
          </p>
          <h4>§ 5 Erfüllungsort</h4>
          <p>
            Soweit sich aus dem Vertrag nichts anderes ergibt, ist der Erfüllungsort unser Geschäftssitz.
          </p>
          <h4>§ 6 Gefahrübergang</h4>
          <p>
            (1) Grundsätzlich ist Lieferung „ab Werk“ vereinbart. Jede Gefahr geht auf den Besteller über, sobald
            die Sendung das Werk verlässt oder abhol- bzw. versandbereit gemeldet ist. Dies gilt auch für
            Teilleistungen.
          </p>
          <p>
            (2) Verlangt der Besteller den Versand an einen anderen Ort als den Erfüllungsort, so geht die Gefahr
            auf den Besteller über, sobald die Sendung dem Spediteur, dem Frachtführer oder der sonst zur Ausführung
            der Versendung bestimmten Person oder Anstalt übergeben wurde. Die gilt auch für Teilleistungen.
          </p>
          <p>
            (3) Eine Transportversicherung erfolgt nur auf Wunsch und zu Lasten des Bestellers.
          </p>
          <h4>§ 7 Haftung für Mängel</h4>
          <p>
            (1) Der Besteller hat die gelieferte Ware bei Eingang unverzüglich auf Mängel, Falschbelieferung oder
            Mengen-abweichungen zu untersuchen.
          </p>
          <p>
            (2) Beanstandungen sind im Rahmen der handelsrechtlichen Vorschriften (§ 377 HGB), spätestens aber
            innerhalb von 10 Tagen nach Erhalt der Ware und bei verborgenen Mängeln spätestens innerhalb von 3 Tagen
            nach ihrer Entdeckung schriftlich zu erheben.
          </p>
          <p>
            (3) Bei Vorliegen eines Mangels behalten wir uns die Wahl der Art der Nacherfüllung vor. Insbesondere
            werden wir zunächst Fehlmengen nachliefern, Ersatz liefern oder nachbessern. Sind weder Nachbesserung
            noch Ersatz möglich oder endgültig fehlgeschlagen oder nur unzumutbar verzögert möglich, so kann der
            Besteller nach seiner Wahl nach den gesetzlichen Vorschriften den Kaufpreis mindern oder vom Vertrag
            zurücktreten und Schadensersatz gemäß § 8 oder Ersatz seiner Aufwendungen verlangen.
          </p>
          <p>
            (4) Die Frist für die Verjährung von Mängelansprüchen beträgt 12 Monate. Sie beginnt mit Gefahrübergang.
          </p>
          <h4>§ 8 Haftung</h4>
          <p>
            (1) Unsere Haftung für vertragliche Pflichtverletzung sowie aus Delikt ist auf Vorsatz und grobe
            Fahrlässigkeit sowie auf Ersatz des typischerweise entstehenden Schadens beschränkt. Dies gilt nicht bei
            Verletzung von Leben, Körper und Gesundheit des Bestellers, Ansprüchen wegen Verletzung von
            Kardinalpflichten und Ersatz von Verzugschäden (§ 286 BGB). Insoweit haften wir für jeden Grad des
            Verschuldens. Die Haftung im Fall des Lieferverzuges ist jedoch für jede vollendete Woche des Verzuges
            im Rahmen einer pauschalierten Verzugsentschädigung auf 0,5% des Lieferwertes, maximal jedoch nicht mehr
            als 5% des Lieferwertes begrenzt.
            Die Haftung im Fall der Verletzung von Kardinalpflichten wird auf den regelmäßig vorhersehbaren Schaden
            begrenzt. Der Begriff der Kardinalpflicht bezeichnet dabei abstrakt solche Pflichten, deren Einhaltung
            die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der
            Käufer regelmäßig vertrauen darf.
          </p>
          <p>
            (2) Wir haften nicht für die leicht fahrlässige Verletzung unwesentlicher Pflichten aus dem
            Schuldverhältnis.
          </p>
          <p>
            (3) Die vorgenannte Haftungsbeschränkung gilt nicht in den Fällen zwingender gesetzlicher Haftung
            (insbesondere nach dem Produkthaftungsgesetz) sowie bei Übernahme einer Garantie oder schuldhaft
            verursachten Körperschäden.
          </p>
          <p>
            (4) Der Besteller ist verpflichtet, angemessene Maßnahmen zur Schadensabwehr und –minderung zu treffen.
          </p>
          <h4>§ 9 Eigentumsvorbehalt</h4>
          <p>
            (1) Wir behalten uns das Eigentum an der Ware bis zur Erfüllung sämtlicher Forderungen gegen den
            Besteller vor, auch wenn die konkrete Ware bereits bezahlt wurde.
          </p>
          <p>
            (2) Der Besteller darf über unsere Vorbehaltsware nur im ordentlichen Geschäftsgang verfügen, solange er
            seinen Verpflichtungen aus der Geschäftsverbindung mit uns rechtzeitig nachkommt.
          </p>
          <p>
            (3) Alle dem Besteller aus der Veräußerung oder Weiterverarbeitung entstandenen oder noch entstehenden
            Forderungen gegen seine Abnehmer oder andere gesetzliche Ansprüche, die unseren Eigentumsverlust zur
            Folge haben, tritt der Besteller bereits bei Auftragserteilung bis zur vollständigen Tilgung aller
            Forderungen aus der Geschäfts-beziehung mit allen Nebenrechten im Umfang unseres Eigentumsanteils an den
            verkauften oder weiterverarbeiteten Waren zur Sicherung an uns ab. In diesem Fall nimmt der Besteller
            den erhaltenen Kaufpreis lediglich als Treuhänder für uns in Empfang und verpflichtet sich, ihn
            unverzüglich nach Empfang an uns abzuführen. Insbesondere hat der Besteller den empfangenen Betrag
            getrennt von seinem Vermögen als Treuhänder für uns aufzubewahren. Das gleiche gilt für Forderungen aus
            Versicherungs-
            verträgen.
          </p>
          <p>
            (4) Über Zwangsvollstreckungsmaßnahmen Dritter in die Vorbehaltsware hat uns der Besteller unverzüglich
            unter Übergabe der für eine Intervention notwendigen Unterlagen zu unterrichten. Dies gilt auch für
            Beeinträchtigungen sonstiger Art. Unabhängig davon hat der Besteller bereits vorher die Dritten auf die
            an der Ware bestehenden Rechte hinzuweisen. Die uns entstehenden Kosten einer Intervention trägt der
            Besteller, soweit der Dritte nicht in der Lage ist, diese zu erstatten.
          </p>
          <p>
            (5) Übersteigt der Wert der Sicherung unsere Ansprüche gegen den Besteller um mehr als 20%, so haben wir
            auf Verlangen des Bestellers und nach unserer Wahl uns zustehende Sicherheiten in entsprechendem Umfang
            freizugeben.
          </p>
          <p>
            (6) Erfolgt die Zahlung durch den Besteller nicht mehr vertragsgemäß, so können wir unbeschadet unserer
            sonstigen Rechte vom Vertrag zurücktreten und die Kaufsache zurückverlangen.
          </p>
          <p>

            (7) Wir sind berechtigt vom Vertrag zurückzutreten, wenn der Besteller über sein Vermögen einen Antrag
            auf Eröffnung des Insolvenzverfahrens gestellt hat, eine eidesstattliche Versicherung nach § 807 ZPO
            abgegeben oder das Insolvenzverfahren über sein Vermögen eröffnet oder die Eröffnung mangels Masse
            abgelehnt wurde.
          </p>
          <p>
            (8) Der Besteller versichert, dass er über die vorbezeichneten, an uns abgetretenen Ansprüche noch nicht
            anderweitig verfügt hat.
          </p>
          <h4>§ 10 Verjährung eigener Ansprüche</h4>
          <p>
            Unsere Ansprüche auf Zahlung verjähren abweichend von § 195 BGB in fünf Jahren. Hinsichtlich des Beginns
            der Verjährungsfrist gilt § 199 BGB.
          </p>
          <h4>§ 11 Form von Erklärungen</h4>
          <p>
            Rechtserhebliche Erklärungen und Anzeigen, die der Besteller gegenüber uns oder einem Dritten abzugeben
            hat, bedürfen der Schriftform.
          </p>
          <h4>§ 12 Gerichtsstand und anzuwendendes Recht</h4>
          <p>
            (1) Ausschließlicher Gerichtsstand ist bei Verträgen mit Kaufleuten, juristischen Personen des
            öffentlichen Rechts oder öffentlich-rechtlichen Sondervermögen das für unseren Geschäftssitz zuständige
            Gericht in Wuppertal.
          </p>
          <p>
            (2) Es gilt das Recht der Bundesrepublik Deutschland; die Geltung des UN-Kaufrechts ist ausgeschlossen.
          </p>
          <h4>§ 13 Datenspeicherung</h4>
          <p>
            Der Besteller wird hiermit davon in Kenntnis gesetzt, dass wir die im Rahmen der Geschäftsverbindung
            gewonnenen personenbezogenen Daten gemäß den Bestimmungen des Bundesdatenschutzgesetzes verarbeiten.
          </p>
        </article>
      </Wrapper>
      <Spacer/>
    </>
  )
};

export default AGBPage;
